import React, { useState } from 'react';
import {
    Form,
    Image,
    Carousel,
    Modal,
    Button,
    FloatingLabel,
} from 'react-bootstrap';
import { FaPlus, FaEdit, FaRegWindowClose } from 'react-icons/fa';

import './styles/ImageUploader.css';

const ImageUploader = ({ onFileChange, existingImages, existingVideos, setExistingImages, setExistingVideos }) => {
    const [previewFiles, setPreviewFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files) {
            const newFiles = Array.from(files).map((file) => ({
                url: URL.createObjectURL(file),
                type: file.type,
            }));
            setPreviewFiles([...previewFiles, ...newFiles]);
        }
        onFileChange(e);
    };

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleClearAllFiles = () => {
        setPreviewFiles([]);
        setExistingImages(null);
        setExistingVideos(null);
    };

    const handleClearFile = (index) => {
        setPreviewFiles(previewFiles.filter((_, i) => i !== index));
    };

    const handleClearExisingFile = (fileURL, fileType) => {
        if (fileType === 'image') {
            setExistingImages(existingImages.filter((url) => url !== fileURL));
        } else if (fileType === 'video') {
            setExistingVideos(existingVideos.filter((url) => url !== fileURL));
        } else {
            return;
        }
    }

    const renderPreview = (file, index) => {
        console.log({ file });
        if (!file || !file.url) return null;
        const isVideo = file.type.startsWith('video/');
        return isVideo ? (
            <video key={index} controls className='d-block w-100'>
                <source src={file.url} type={file.type} />
                Your browser does not support the video tag.
            </video>
        ) : (
            <Image
                key={index}
                src={file.url}
                thumbnail
                className='d-block w-100'
            />
        );
    };

    const renderImage = (url, index) => {
        return (
            <Image
                key={index}
                src={url}
                thumbnail
                className='d-block w-100'
            />
        );
    }

    const renderVideo = (url, index) => {
        return (
            <video key={index} controls className='d-block w-100'>
                <source src={url} type='video/mp4' />
                Your browser does not support the video tag.
            </video>
        );
    }

    return (
        <>
            <Form.Group controlId='formFiles'>
                <FloatingLabel>Images and Videos</FloatingLabel>
                <div className='image-uploader'>
                    {(previewFiles.length === 0 && !existingImages && !existingVideos) ? (
                        <label
                            className='image-placeholder'
                            onClick={handleOpenModal}
                        >
                            <FaPlus size={48} />
                        </label>
                    ) : (
                        <Carousel>
                            {previewFiles.map((file, index) => (
                                <Carousel.Item
                                    key={index}
                                    className='carousel-item-container'
                                    onClick={() => {
                                        if (!file.type.startsWith('video/')) {
                                            handleOpenModal();
                                        }
                                    }}
                                >
                                    {renderPreview(file, index)}
                                    <div
                                        className='clear-icon'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearFile(index);
                                        }}
                                    >
                                        <FaRegWindowClose size={20} /> &nbsp;
                                        Remove
                                    </div>
                                </Carousel.Item>
                            ))}
                            {existingImages && existingImages.map((url, index) => (
                                <Carousel.Item
                                    key={index}
                                    className='carousel-item-container'
                                >
                                    {renderImage(url, index)}
                                    <div
                                        className='clear-icon'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearExisingFile(url, 'image');
                                        }}
                                    >
                                        <FaRegWindowClose size={20} /> &nbsp;
                                        Remove
                                    </div>
                                </Carousel.Item>
                            ))}
                            {existingVideos && existingVideos.map((url, index) => (
                                <Carousel.Item
                                    key={index}
                                    className='carousel-item-container'
                                >
                                    {renderVideo(url, index)}
                                    <div
                                        className='clear-icon'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearExisingFile(url, 'video');
                                        }}
                                    >
                                        <FaRegWindowClose size={20} /> &nbsp;
                                        Remove
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                </div>

                <Button variant='primary' onClick={handleOpenModal} style={{float: 'right', marginTop:'2vh'}}>
                    <FaEdit size={16} className='mr-1' />
                    Manage Files
                </Button>
            </Form.Group>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {((previewFiles.length > 0 || existingImages || existingVideos) && (
                        <Carousel>
                            {previewFiles.map((file, index) => (
                                <Carousel.Item
                                    key={index}
                                    className='carousel-item-container'
                                >
                                    {renderPreview(file, index)}
                                    <div
                                        className='clear-icon'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearFile(index);
                                        }}
                                    >
                                        <FaRegWindowClose size={20} /> &nbsp;
                                        Remove
                                    </div>
                                </Carousel.Item>
                            ))}
                            {existingImages && existingImages.map((url, index) => (
                                <Carousel.Item
                                    key={index}
                                    className='carousel-item-container'
                                >
                                    {renderImage(url, index)}
                                    <div
                                        className='clear-icon'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearExisingFile(url, 'image');
                                        }}
                                    >
                                        <FaRegWindowClose size={20} /> &nbsp;
                                        Remove
                                    </div>
                                </Carousel.Item>
                            ))}
                            {existingVideos && existingVideos.map((url, index) => (
                                <Carousel.Item
                                    key={index}
                                    className='carousel-item-container'
                                >
                                    {renderVideo(url, index)}
                                    <div
                                        className='clear-icon'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearExisingFile(url, 'video');
                                        }}
                                    >
                                        <FaRegWindowClose size={20} /> &nbsp;
                                        Remove
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )) || (
                        <div className='image-uploader mt-3'>
                            <input
                                type='file'
                                multiple
                                onChange={handleFileChange}
                                accept='image/*,video/*'
                                style={{ display: 'none' }}
                                id='fileUpload'
                            />
                            <label
                                htmlFor='fileUpload'
                                className='image-placeholder'
                            >
                                <FaPlus size={48} />
                            </label>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClearAllFiles}>
                        Clear All Files
                    </Button>
                    <Button variant='primary'>
                        <label
                            htmlFor='fileUpload'
                            className='d-flex align-items-center'
                        >
                            <FaPlus size={16} className='mr-1' />
                            <input
                                type='file'
                                multiple
                                onChange={handleFileChange}
                                accept='image/*,video/*'
                                id='fileUpload'
                                style={{ display: 'none' }}
                            />
                            &nbsp;&nbsp;Add Files
                        </label>
                    </Button>
                    <Button variant='primary' onClick={handleCloseModal}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImageUploader;
