import React, { useEffect, useState } from 'react';
import { getAccessories } from '../../services/Accessories';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBIcon,
    MDBBtn
} from "mdb-react-ui-kit";
import { Link } from 'react-router-dom';
import UserLoader from '../Loader/UserLoader';


function Accessories() {
    const [availableProducts, setAvailableProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 4;
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        fetchAvailableProducts();
    }, [page]);

    const fetchAvailableProducts = async () => {
        try {
            setLoading(true);
            const response = await getAccessories({
                page_no: page,
                page_size: pageSize,
                sort_by: 'name',
                sort_order: 'DESC',
                search: '',
            });
            const { data, totRecords } = response.accessories;
            setAvailableProducts(prevProducts => {
                const newProducts = data.filter(newProduct => !prevProducts.some(existingProduct => existingProduct._id === newProduct._id));
                return [...prevProducts, ...newProducts];
            });
            setTotalRecords(totRecords);
        } catch (error) {
            console.log("Error fetching products:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadMore = async () => {
        setLoadingButton(true);
        setPage(page + 1);
        try {
            const response = await getAccessories({
                page_no: page + 1,
                page_size: pageSize,
                sort_by: 'name',
                sort_order: 'DESC',
                search: '',
            });
            const { data } = response.accessories;
            setAvailableProducts(prevProducts => {
                const newProducts = data.filter(newProduct => !prevProducts.some(existingProduct => existingProduct._id === newProduct._id));
                return [...prevProducts, ...newProducts];
            });
        } catch (error) {
            console.log("Error fetching more products:", error);
        } finally {
            setLoadingButton(false);
        }
    };

    const handleProductImage = (urls = []) => {
        const defaultImageUrl = "https://cdn.moglix.com/p/uvwVBrwiX71Hg-xxlarge.jpg";
        if (urls.length === 0) {
            return defaultImageUrl;
        }
        return urls[0];
    };

    return (
        <div style={{ padding: "70px" }}>
            <MDBContainer fluid className="my-5">
                <h2 className='mb-4 text-center' style={{fontSize:"bold"}}>Accessories</h2>
                <MDBRow>
                    {availableProducts.map(product => (
                        <MDBCol md="6" lg="3" className="mb-4" key={product._id}>
                            <Link to={`/product/${product._id}`} key={product._id}>
                                <MDBCard className="product-card" style={{ height: "400px", display: "flex", flexDirection: "column" }}>
                                    <div className="d-flex justify-content-between p-3">
                                        <p className="lead mb-0">{product.name}</p>                                       
                                    </div>
                                    <MDBCardImage
                                        src={handleProductImage(product.imageURLS)}
                                        position="top"
                                        alt={product.name}
                                        style={{ height: "200px", objectFit: "cover" }}
                                    />
                                    <MDBCardBody style={{ flex: "1", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <div className="d-flex justify-content-between">
                                            <p className="small">
                                                <a href="#!" className="text-muted">
                                                    {product.category || "printer"}
                                                </a>
                                            </p>
                                            <p className="small text-danger">
                                                <s>₹{product.price}</s>
                                            </p>
                                        </div>

                                        <div className="d-flex justify-content-between mb-3">
                                            <h5 className="mb-0">{product.name}</h5>
                                            <h5 className="text-dark mb-0">${product.discountedPrice || "323"}</h5>
                                        </div>

                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="text-muted mb-0">
                                                Available: <span className="fw-bold">{product.available || "Yes"}</span>
                                            </p>
                                            <div className="ms-auto text-warning">
                                                {[...Array(5)].map((star, i) => (
                                                    <MDBIcon
                                                        key={i}
                                                        fas
                                                        icon="star"
                                                        className={i < product.rating ? "text-warning" : "text-muted"}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </Link>
                        </MDBCol>
                    ))}
                </MDBRow>
                {loading && <UserLoader />}
                {!loading && availableProducts.length < totalRecords && (
                    <div className="text-center my-4">
                        <MDBBtn onClick={loadMore} disabled={loadingButton}>
                            {loadingButton ? "Loading..." : "Load More"}
                        </MDBBtn>
                    </div>
                )}
            </MDBContainer>
        </div>
    );
}

export default Accessories
