import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCardImage } from 'mdb-react-ui-kit';

function Footer() {
    return (
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
              <img
              src="/logoMain.png"
              alt="Logo"
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
               Az Automation
              </h6>
              <p>
              We are dedicated to providing high-quality printers, photocopy machines, and their parts and accessories.
               We believe in delivering exceptional products
               and fostering a customer-centric approach that ensures satisfaction and reliability.
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Social Links</h6>
              <p>
                <a href='https://www.instagram.com/azofficeautomation?igsh=NWpqYmI1enlicGo1' className='text-reset' target='_blank'>
                  Instagram
                </a>
              </p>
              <p>
                <a href='https://www.facebook.com/azofficeautomation?mibextid=ZbWKwLs' className='text-reset' target="_blank">
                  Facebook
                </a>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='/aboutUs' className='text-reset'>
                  About us
                </a>
              </p>
              <p>
                <a href='/contactUs' className='text-reset'>
                  Contact us
                </a>
              </p>
              <p>
                <a href='/products' className='text-reset'>
                Products
                </a>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                7, 13th Cross Rd, 5th Block HMT Layout, Nagasandra, Bengaluru, Karnataka 560073
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                az.officeautomations@gmail.com
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> +918553202432
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
    );
}

export default Footer;
