import React from 'react'
import AvailableProducts from '../../components/UserProducts/AvailableProducts'
import NewArraivals from '../../components/UserProducts/NewArraivals'
import MainBanner from '../../components/Banners/MainBanner'

const Home = () => {

  return (
    <div>
      <MainBanner />
      <AvailableProducts />
      <NewArraivals />
    </div>
  )
}

export default Home
