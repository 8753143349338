import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import ImageUploader from './ImageUploader';
import { v4 as uuidv4 } from 'uuid';
import { uploadPreviewContent, uploadDocuments } from '../../api/S3';
import { createProduct, getProductById } from '../../services/Products';
import { useParams } from 'react-router-dom';

const ProductForm = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    description: ''
  });
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [existingImages, setExistingImages] = useState(null);
  const [existingVideos, setExistingVideos] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState(null);
  const [existingDocuments, setExistingDocuments] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams(); // Get ID from URL
  console.log({ id });

  useEffect(() => {
    if (!id) return;
    const fetchProduct = async () => {
        try {
            setLoading(true);
            const response = await getProductById(id);
            setProduct(response.product);
            setFormData({
                name: response?.product?.name || "",
                price: response?.product?.price || "",
                description: response?.product?.description || ""
            });
            setExistingImages(response?.product?.imageURLS || []);
            setExistingVideos(response?.product?.videoURLS || []);
            setExistingDocuments(response?.product?.documentURLS || []);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    fetchProduct();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleDocumentChange = (e) => {
    setSelectedDocuments(e.target.files);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("ENtered handleSubmit");
    // validate data
    const productId = uuidv4();
    
    if (selectedFiles && selectedFiles.length > 0) {
      const { uploadResponses: imageUploadResponses } = await uploadPreviewContent(productId, selectedFiles);
      console.log({ productId, imageUploadResponses });
    }

    if (selectedDocuments && selectedDocuments.length > 0) {
      const { uploadResponses: documentUploadResponses } = await uploadDocuments(productId, selectedDocuments);
      console.log({ productId, documentUploadResponses });
    }

    const product = {
      ...formData
    };

    if (selectedFiles && selectedFiles.length > 0 || selectedDocuments && selectedDocuments.length > 0) {
      product.s3Id = productId;
    }

    const creationResponse = await createProduct(product);

    console.log({ product, creationResponse });
  };

  return (
      <Form style={{ marginTop: '5px' }}>
          <Row className='justify-content-md-center'>
              <Col md={10}>
                  <h2>Add New Product</h2>
                  <Form onSubmit={handleSubmit}>
                      <Row>
                          <Col md={4}>
                              <ImageUploader
                                  existingImages={existingImages}
                                  existingVideos={existingVideos}
                                  setExistingImages={setExistingImages}
                                  setExistingVideos={setExistingVideos}
                                  onFileChange={handleFileChange}
                              />
                          </Col>
                          <Col md={8}>
                              <Form.Group controlId='formName'>
                                  <FloatingLabel
                                      controlId='floatingInput'
                                      label='Name'
                                      className='mb-3'
                                  >
                                      <Form.Control
                                          type='text'
                                          name='name'
                                          value={formData.name}
                                          onChange={handleChange}
                                          required
                                      />
                                  </FloatingLabel>
                              </Form.Group>

                              <Form.Group controlId='formPrice'>
                                  <FloatingLabel
                                      controlId='floatingInput'
                                      label='Price'
                                      className='mb-3'
                                  >
                                      <Form.Control
                                          type='number'
                                          name='price'
                                          value={formData.price}
                                          onChange={handleChange}
                                          required
                                      />
                                  </FloatingLabel>
                              </Form.Group>

                              <Form.Group controlId='formDescription'>
                                  <FloatingLabel
                                      controlId='floatingTextarea2'
                                      label='Description'
                                      className='mb-3'
                                  >
                                      <Form.Control
                                          as='textarea'
                                          name='description'
                                          value={formData.description}
                                          onChange={handleChange}
                                          rows={3}
                                          required
                                      />
                                  </FloatingLabel>
                              </Form.Group>

                              <Form.Group controlId='formDocuments'>
                                  <Form.Label controlId='formFile'>
                                      Upload Documents
                                  </Form.Label>
                                  <Form.Control
                                      type='file'
                                      multiple
                                      onChange={handleDocumentChange}
                                      // style={{padding: '3.5vh 0 0 0.65vw', float: 'right'}}
                                  />
                              </Form.Group>

                              <Button
                                  variant='primary'
                                  onClick={() => handleSubmit()}
                                  style={{ marginTop: '1vh', float: 'right' }}
                              >
                                  Add Product
                              </Button>
                          </Col>
                      </Row>
                  </Form>
              </Col>
          </Row>
      </Form>
  );
};

export default ProductForm;
