import React from 'react';
import { Grid, Menu, Dropdown, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import './navbarStyle.css';

const NavigationBar = ({ username, onSignOut, toggleSidebar }) => {
   
    const handleSignOut = () => {
        onSignOut();
    };

    return (
        <Grid>
            <Grid.Column>
                <Menu inverted color='red' size='large' stackable>
                    <Menu.Item header>
                        <img
                            src="/logoMain.png"
                            alt="Logo"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                    </Menu.Item>

                    <Menu.Menu position='right'>
                        <Menu.Item name='home' as={NavLink} to="/" exact />
                        <Menu.Item name='add-product' as={NavLink} to="/admin/add-product" />
                        
                        <Dropdown item text={username} className="text-white" style={{ color: 'white' }}>
                            <Dropdown.Menu>
                                <Dropdown.Item>About</Dropdown.Item>
                                <Dropdown.Item>Contact Us</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as="button" onClick={handleSignOut}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* Hamburger menu icon to toggle sidebar */}
                        <Menu.Item className='navbar-toggle' onClick={toggleSidebar}>
                            <Icon name='sidebar' />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Grid.Column>
        </Grid>
    );
};

export default NavigationBar;
