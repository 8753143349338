import React from 'react';
import './style.css'; // Import the custom CSS file
import { Link } from 'react-router-dom';

export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#ffa62b", color: "white" }}>
        <div className="container">
          {/* Navbar brand */}
          <a href="/">
            <img
              src="/logoMain.png"
              alt="Logo"
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
          </a>

          {/* Toggle button */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarButtonsExample"
            aria-controls="navbarButtonsExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Collapsible wrapper */}
          <div className="collapse navbar-collapse" id="navbarButtonsExample">
            {/* Left links */}
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{marginLeft:"50px"}}>              
              <li className="nav-item">
                <a className="nav-link custom-nav-item" href="/products">Products</a>
              </li>
              <li className="nav-item">
                <a className="nav-link custom-nav-item" href="/aboutUs">About Us</a>
              </li>
            </ul>

            {/* Right links */}
            <div className="d-flex align-items-center">
              <button type="button" className="btn btn-light me-3">
              <Link to={"/contactUs"}>
                Contact Us
              </Link>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
