import { get, post, put, del } from 'aws-amplify/api';
const apiName = 'printerService';

const lambdaGet = async (path, queryParams) => {
    const restOperation = get({
        apiName,
        path,
        options: { queryParams },
    });
    const response = await restOperation.response;
    const data = await (response && response.body && response.body.json());
    return data;
};

const lambdaPost = async (path, body) => {
    const restOperation = post({
        apiName,
        path,
        options: { body },
    });
    const response = await restOperation.response;
    const data = await (response && response.body && response.body.json());
    return data;
}

const lambdaPut = async (path, body) => {
    const restOperation = put({
        apiName,
        path,
        options: { body },
    });
    const response = await restOperation.response;
    const data = await (response && response.body && response.body.json());
    return data;
}

const lambdaDelete = async (path) => {
    const restOperation = del({
        apiName,
        path,
    });
    const response = await restOperation.response;
    const data = await (response && response.body && response.body.json());
    return data;
}

export { lambdaGet, lambdaPost, lambdaPut, lambdaDelete };
