import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserLoader from '../../Loader/UserLoader';
import { getProductById } from '../../../services/Products';
import { defaultImageUrl } from '../../../utils/constants';
import './style.css';

function ProductDetail(props) {
    const { id } = useParams(); // Get ID from URL
    const [product, setProduct] = useState({});
    const [mainImage, setMainImage] = useState(defaultImageUrl);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    console.log(props.edit)

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                setLoading(true);
                const response = await getProductById(id);
                setProduct(response.product);
                setMainImage(response.product?.imageURLS?.[0] || defaultImageUrl);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id]);

    const handleThumbnailClick = (imageUrl) => {
        setMainImage(imageUrl);
    };

    return (
        <div>
            {loading ? (
                <div className="loader-container">
                    <UserLoader />
                </div>
            ) : (
                product && (
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-md-2 product-thumbnails">
                                {product.imageURLS?.slice(0, 4).map((url, index) => (
                                    <img
                                        key={index}
                                        src={url || defaultImageUrl}
                                        alt={`Thumbnail ${index + 1}`}
                                        onClick={() => handleThumbnailClick(url || defaultImageUrl)}
                                        className="thumbnail-image"
                                    />
                                ))}
                            </div>
                            <div className="col-md-5 main-image">
                                <img src={mainImage} alt="Main Product Image" className="main-image-display"/>
                            </div>
                            <div className="col-md-5">
                                <h2>{product.name}</h2>
                                <p className="text-muted">{product.category || 'Printer'}</p>
                                <h3 className="text-danger">{product.price !== undefined && product.price !== null && product.price !== '' ? '₹' + product.price : 'Price unavailable'}
                                </h3>
                                <p>{product.description}</p>                                                           
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default ProductDetail;
