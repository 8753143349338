import { lambdaDelete, lambdaGet, lambdaPost, lambdaPut } from '../api/amplify';
const path = '/accessories';

const getAccessories = async ({
    page_no = 1,
    page_size = 10,
    sort_by = 'name',
    sort_order = 'DESC',
    search = '',
} = {}) => {
    const queryParams = {
        page_no: page_no,
        page_size: page_size,
        sort_by: sort_by,
        sort_order: sort_order,
        search: search,
    };
    const data = await lambdaGet(path, queryParams);
    return data;
};

const getAccessoryById = async (productId) => {
    const data = await lambdaGet(`${path}/${productId}`);
    return data;
};

const createAccessory = async (product) => {
    const data = await lambdaPost(path, { product });
    return data;
};

const updateAccessory = async (product) => {
    const data = await lambdaPut(path, { product });
    return data;
};

const deleteAccessory = async (productId) => {
    const data = await lambdaDelete(`${path}/${productId}`);
    return data;
};

export {
    getAccessories,
    getAccessoryById,
    createAccessory,
    updateAccessory,
    deleteAccessory,
};
