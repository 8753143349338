import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";

const NewArraivals = () => {
  return (
    <div>
      <MDBContainer
        fluid
        className="py-5"
        style={{ backgroundColor: "#f3f2f2", color: "#000" }}
      >
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="10" xl="8" className="text-center">
            <h3 className="fw-bold mb-4">Testimonials</h3>
            <p className="mb-4 pb-2 mb-md-5 pb-md-0">
              Read our customer reviews on{" "}
              <a
                href="https:jsdl.in/RSL-YUD1729001615"
                target="_blank"
                rel="noopener noreferrer"
              >
                Justdial
              </a>
              .
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="text-center">
          <MDBCol md="4" className="mb-4 mb-md-0">
            <MDBCard className="h-100 d-flex flex-column">
              <MDBCardBody className="py-4 mt-2 flex-grow-1 d-flex flex-column">
                <div className="d-flex justify-content-center mb-4">
                  <img
                    src="/images/Madhusudan.png"
                    className="rounded-circle shadow-1-strong"
                    width="100"
                    height="100"
                  />
                </div>
                <h5 className="font-weight-bold">Madhusudan</h5>
                <MDBTypography
                  listUnStyled
                  className="d-flex justify-content-center"
                >
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                </MDBTypography>
                <p className="mb-2">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  Professional in supply of machine, systems and spares at
                  reasonable price. Value for money. After sales service is very
                  good.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol md="4" className="mb-4 mb-md-0">
            <MDBCard className="h-100 d-flex flex-column">
              <MDBCardBody className="py-4 mt-2 flex-grow-1 d-flex flex-column">
                <div className="d-flex justify-content-center mb-4">
                  <img
                    src="/images/Nagaraj.png"
                    className="rounded-circle shadow-1-strong"
                    width="100"
                    height="100"
                  />
                </div>
                <h5 className="font-weight-bold">Nagaraj</h5>
                <MDBTypography
                  listUnStyled
                  className="d-flex justify-content-center"
                >
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                </MDBTypography>
                <p className="mb-2">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  As per my experience, I got a good machine with no major
                  issues till now. I can rely on this dealer for service on
                  time.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol md="4" className="mb-4 mb-md-0">
            <MDBCard className="h-100 d-flex flex-column">
              <MDBCardBody className="py-4 mt-2 flex-grow-1 d-flex flex-column">
                <div className="d-flex justify-content-center mb-4">
                  <img
                    src="/images/Nithesh.png"
                    className="rounded-circle shadow-1-strong"
                    width="100"
                    height="100"
                  />
                </div>
                <h5 className="font-weight-bold">Nithesh</h5>
                <MDBTypography
                  listUnStyled
                  className="d-flex justify-content-center"
                >
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star" size="sm" color="info" />
                  </li>
                  <li>
                    <MDBIcon fas icon="star-half-alt" size="sm" color="info" />
                  </li>
                </MDBTypography>
                <p className="mb-2">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  Totally I am satisfied. Really very good service and response
                  from supplier at the right time.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow className="d-flex justify-content-center mt-5">
          <MDBCol md="10" xl="8" className="text-center">
            <h3 className="fw-bold mb-4">Our Clients</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow className="text-center">
          <MDBCol md="4" className="mb-4 mb-md-0">
            <MDBCard className="h-100 d-flex flex-column">
              <MDBCardBody className="py-4 mt-2 flex-grow-1 d-flex flex-column">
                <div className="d-flex justify-content-center mb-4">
                  <img src="/images/kesari.png" style={{ width: '4.17vw' }} />
                </div>
                <h5 className="font-weight-bold">KESARI</h5>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol md="4" className="mb-4 mb-md-0">
            <MDBCard className="h-100 d-flex flex-column">
              <MDBCardBody className="py-4 mt-2 flex-grow-1 d-flex flex-column">
                <div className="d-flex justify-content-center mb-4">
                  <img src="/images/giridhanvaschool.png" style={{ width: '20.83vw' }} />
                </div>
                <h5 className="font-weight-bold">GIRIDHANVASCHOOL</h5>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol md="4" className="mb-4 mb-md-0">
            <MDBCard className="h-100 d-flex flex-column">
              <MDBCardBody className="py-4 mt-2 flex-grow-1 d-flex flex-column">
                <div className="d-flex justify-content-center mb-4">
                  <img src="/images/acobl.png" style={{ width: "31.25vw" }} />
                </div>
                <h5 className="font-weight-bold">ACOBL</h5>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default NewArraivals;
