/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cloud_logic_custom": [
        {
            "name": "printerService",
            "endpoint": "https://iwixcta4uc.execute-api.ap-south-1.amazonaws.com/prod",
            "region": "ap-south-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-south-1:ad4f85b9-ac0f-402a-83d8-4fa896c752ba",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_xKGUMkZI7",
    "aws_user_pools_web_client_id": "1ob62jc6ian9e6128m2hgt847k",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "product-image-bucket0962f-prod",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
