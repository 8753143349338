import { lambdaPost } from '../api/amplify';
const path = '/enquiries';

const createEnquiry = async (enquiry) => {
    const data = await lambdaPost(path, { enquiry });
    return data;
};

export {
    createEnquiry,
};