import { uploadData } from "aws-amplify/storage";
import { Promise as bluebirdPromise } from 'bluebird';

// const uploadPreviewContent = async (productId, files) => {
//     try {
//         const uploadResponses = await bluebirdPromise.map(
//           Array.from(files),
//           async (file) => {
//             const imageKey = `${productId}/${Date.now()}-${file.name}`;
//             return uploadData({
//               path: `public/PreviewContent/${imageKey}`,
//               data: file,
//             });
//           }
//         );
//         return { productId, uploadResponses };
//     } catch (error) {
//         throw new Error('Error uploading images'); 
//     }
// };

const uploadPreviewContent = async (productId, files) => {
  try {
      const uploadResponses = await bluebirdPromise.map(
          Array.from(files),
          async (file) => {
              let folder;
              if (file.type.startsWith('image/')) {
                  folder = 'Images';
              } else if (file.type.startsWith('video/')) {
                  folder = 'Videos';
              } else {
                  throw new Error('Unsupported file type');
              }

              const fileKey = `${folder}/${productId}/${Date.now()}-${file.name}`;
              return uploadData({
                  path: `public/${fileKey}`,
                  data: file,
              });
          }
      );
      return { productId, uploadResponses };
  } catch (error) {
      console.error('Error uploading files:', error);
      throw new Error('Error uploading files');
  }
};

const uploadDocuments = async (productId, files) => {
    try {
        const uploadResponses = await bluebirdPromise.map(
          Array.from(files),
          async (file) => {
            const documentKey = `${productId}/${Date.now()}-${file.name}`;
            return uploadData({
                path: `public/Documents/${documentKey}`,
                data: file,
            });
            }
        );
        return { productId, uploadResponses };
    }
    catch (error) {
        throw new Error('Error uploading documents');
    }
}

export { uploadPreviewContent, uploadDocuments };