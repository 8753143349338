import React from 'react'
import { Link } from 'react-router-dom'

function AboutUs() {
  return (
    <div>
      {/* About Section */}
      <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8">
              <h3 className="fs-5 mb-2 text-secondary text-uppercase">About</h3>
              <h2 className="display-5 mb-4">
                A Z OFFICE AUTOMATION: Your Premier One-Stop IT Solution
              </h2>
              <p className="lead mb-4">
                In today’s fast-paced digital landscape, effective technology solutions are crucial for success. At A Z Office Automation, we provide a comprehensive suite of IT services designed to empower your business and streamline your operations.
              </p>
              <Link to={'/contactUs'}>
                <button
                  type="button"
                  className="btn btn-lg btn-primary mb-3 mb-md-4 mb-xl-5"
                >
                  Connect Now
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0">
            <div className="col-12 col-lg-6">
              <div className="card bg-light p-3 m-0">
                <div className="row gy-3 gy-md-0 align-items-md-center">
                  <div className="col-md-5">
                    <img
                      src={"/images/WhyUs.png"}
                      className="img-fluid rounded-start"
                      alt="High-Quality Photocopiers"
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="card-body p-0">
                      <h2 className="card-title h4 mb-3">
                        Sales of High-Quality Photocopiers
                      </h2>
                      <p className="card-text lead">
                        Explore our extensive range of advanced photocopiers designed for performance, reliability, and superior print quality. Whether you need a compact solution for a small office or a high-volume machine for larger operations, we have the perfect fit for you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card bg-light p-3 m-0">
                <div className="row gy-3 gy-md-0 align-items-md-center">
                  <div className="col-md-5">
                    <img
                      src={"/images/itsolutions.webp"}
                      className="img-fluid rounded-start"
                      alt="Integrated IT Solutions"
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="card-body p-0">
                      <h2 className="card-title h4 mb-3">
                        Integrated IT Solutions
                      </h2>
                      <p className="card-text lead">
                        Whether you need desktop and laptop support, security surveillance, networking services, or display solutions, we are your single source for all IT needs, ensuring seamless functionality across your organization.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card bg-light p-3 m-0">
                <div className="row gy-3 gy-md-0 align-items-md-center">
                  <div className="col-md-5">
                    <img
                      src={"/images/expertise.webp"}
                      className="img-fluid rounded-start"
                      alt="Expertise You Can Trust"
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="card-body p-0">
                      <h2 className="card-title h4 mb-3">
                        Expertise You Can Trust
                      </h2>
                      <p className="card-text lead">
                        Our team of seasoned professionals brings extensive knowledge and experience, offering tailored strategies that align with your specific goals. We are committed to delivering exceptional service and support every step of the way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card bg-light p-3 m-0">
                <div className="row gy-3 gy-md-0 align-items-md-center">
                  <div className="col-md-5">
                    <img
                      src={"/images/cutting-edge.webp"}
                      className="img-fluid rounded-start"
                      alt="Cutting-Edge Technology"
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="card-body p-0">
                      <h2 className="card-title h4 mb-3">
                        Cutting-Edge Technology
                      </h2>
                      <p className="card-text lead">
                        Stay competitive with our innovative solutions. We leverage the latest advancements to provide robust, scalable systems that adapt to your evolving business requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card bg-light p-3 m-0">
                <div className="row gy-3 gy-md-0 align-items-md-center">
                  <div className="col-md-5">
                    <img
                      src={"/images/client-centric.webp"}
                      className="img-fluid rounded-start"
                      alt="Client-Centric Focus"
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="card-body p-0">
                      <h2 className="card-title h4 mb-3">
                        Client-Centric Focus
                      </h2>
                      <p className="card-text lead">
                        At A Z Office Automation, your success is our mission. We pride ourselves on building strong partnerships with our clients, ensuring that we understand your needs and deliver personalized, responsive service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="text-center mt-5">
                <h2 className="h4 mb-3">Elevate Your Business with AZ Office Automation</h2>
                <p className="lead">
                  <span className="text-primary">🌟</span> Ready to Elevate Your Operations? Don’t let IT challenges hold you back. Connect with us today, and let’s explore how A Z Office Automation can drive efficiency, enhance security, and unlock the full potential of your business!
                </p>
                <Link to={'/contactUs'}>
                  <button type="button" className="btn btn-lg btn-primary">
                    Get in Touch
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}


export default AboutUs
