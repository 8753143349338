import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Route,
    Navigate,
    Routes,
} from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import ProductForm from './components/Products/ProductsForm';
import '@aws-amplify/ui-react/styles.css';
import Home from './pages/Home/Home';
import NavigationBar from './components/NavigationBar/NavigationBar';
import LeftPanel from './components/NavigationBar/LeftPanel';
import Navbar from './components/Users/Navbar/Navbar';
import Footer from './components/Users/Footer/Footer';
import ProductDetail from './components/Products/DetailedView/ProductDetail';
import AvailableProducts from './components/UserProducts/AvailableProducts';
import './App.css';
import ContactUs from './pages/ContactUs/ContactUs';
import AboutUs from './pages/AboutUs/AboutUs';
import Accessories from './components/Accessories/Accessories';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminPage from './pages/Admin/Admin';
import ProductsPage from './pages/Products/Products';

const PrivateRoute = ({ element, user }) => {
    return user ? element : <Navigate to='/signin' />;
};

const AdminLayout = ({ children, signOut, user }) => (
    <div
        className='AdminLayout'
        style={{ display: 'flex', flexDirection: 'column' }}
    >
        <NavigationBar onSignOut={signOut} username={user} />
        <div style={{ display: 'flex', flex: 1 }}>
            <LeftPanel />
            <div style={{ flex: 1 }}>{children}</div>
        </div>
    </div>
);

const UserLayout = ({ children }) => (
    <div className='user-layout'>
        <Navbar />
        <main className='flex-grow-1'>{children}</main>
        <Footer />
    </div>
);

const AuthenticatedComponents = ({ children, ...rest }) => {
    return (
        <Authenticator hideSignUp={true}>
            {({ signOut, user }) => (
                <AdminLayout signOut={signOut} user={user}>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, { ...rest, user })
                )}
            </AdminLayout>
            )}
        </Authenticator>
    );
};

function App() {
    return (
        <><Router>
            <Routes>
                {/* Public routes */}
                <Route
                    path='/'
                    element={<UserLayout>
                        <Home />
                    </UserLayout>} />
                <Route
                    path='/product/:id'
                    element={<UserLayout>
                        <ProductDetail />
                    </UserLayout>} />
                <Route
                    path='/products'
                    element={<UserLayout>
                        <ProductsPage />
                    </UserLayout>} />
                <Route
                    path='/contactUs'
                    element={<UserLayout>
                        <ContactUs />
                    </UserLayout>} />
                <Route
                    path='/aboutUs'
                    element={<UserLayout>
                        <AboutUs />
                    </UserLayout>} />
                <Route
                    path='/services'
                    element={<UserLayout>
                        <AboutUs />
                    </UserLayout>} />
                <Route
                    path='/accessories'
                    element={<UserLayout>
                        <Accessories />
                    </UserLayout>} />
                <Route
                    path='/products/printers/services'
                    element={<UserLayout>
                        <AvailableProducts category='services' />
                    </UserLayout>} />
                <Route
                    path='/products/printers/pre-owned-machines'
                    element={<UserLayout>
                        <AvailableProducts category='pre-owned-machines' />
                    </UserLayout>} />
                <Route
                    path='/products/printers/accessories-and-spares'
                    element={<UserLayout>
                        <AvailableProducts category='accessories-and-spares' />
                    </UserLayout>} />
                <Route
                    path='/products/cctv'
                    element={<UserLayout>
                        <AvailableProducts category='cctv' />
                    </UserLayout>} />
                <Route
                    path='/products/ups'
                    element={<UserLayout>
                        <AvailableProducts category='ups' />
                    </UserLayout>} />
                <Route
                    path='/products/smart-board'
                    element={<UserLayout>
                        <AvailableProducts category='smart-board' />
                    </UserLayout>} />
                <Route
                    path='/products/networking'
                    element={<UserLayout>
                        <AvailableProducts category='networking' />
                    </UserLayout>} />
                {/* <Route path="/category" element={<UserLayout><Category /></UserLayout>} />
<Route path="/product/:id" element={<UserLayout><ProductDetail /></UserLayout>} />
<Route path="/signin" element={<UserLayout><SignIn /></UserLayout>} /> */}

                {/* Protected routes */}
                <Route
                    path='/admin/add-product'
                    element={
                        <AuthenticatedComponents>
                            <ProductForm />
                        </AuthenticatedComponents>
                    }
                />
                <Route
                    path='/admin/manage-products'
                    element={
                        <AuthenticatedComponents>
                            <AvailableProducts manager/>
                        </AuthenticatedComponents>
                    }
                />
                <Route
                    path='/admin/product/:id'
                    element={
                        <AuthenticatedComponents>
                            <ProductForm />
                        </AuthenticatedComponents>
                    }
                />
                <Route
                    path='/admin'
                    element={
                        <AuthenticatedComponents>
                            <AdminPage />
                        </AuthenticatedComponents>
                    }
                />

                {/* default route */}
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
        </Router><ToastContainer /></>

    );
}

export default App;
