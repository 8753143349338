// build  acard strucutre for navigating to different admin pages

import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText
} from 'mdb-react-ui-kit';

const adminPages = [
    {
        title: 'Manage Products',
        description: 'Add, update, and delete products',
        link: '/admin/manage-products'
    },
    {
        title: 'Manage Accessories',
        description: 'Add, update, and delete accessories',
        link: '/admin/manage-accessories'
    },
    {
        title: 'Manage Banners',
        description: 'Add, update, and delete banners',
        link: '/admin/manage-banners'
    },
    {
        title: 'View Enquiries',
        description: 'View and respond to customer enquiries',
        link: '/admin/view-enquiries'
    },
]

const AdminPage = () => {
    return (
        <div className='d-flex justify-content-center align-items-center flex-wrap vh-100'>
            {adminPages.map((page, index) => (
                <MDBCard
                    key={page.title}
                    className='m-3 fancy-card'
                    style={{ maxWidth: '22rem', cursor: 'pointer', transform: 'scale(1)', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', transition: 'transform 0.1s ease, box-shadow 0.1s ease' }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'scale(1.1)';
                        e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 0, 0, 0.2)';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 0, 0, 0.1)';
                    }}
                >
                    <MDBCardBody onClick={() => window.location.href = page.link}>
                        <MDBCardTitle>{page.title}</MDBCardTitle>
                        <MDBCardText>{page.description}</MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            ))}
        </div>
    );
}

export default AdminPage;