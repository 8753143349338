import React from 'react';


function UserLoader() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="text-center">
        <div className="spinner-grow text-dark m-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default UserLoader;
