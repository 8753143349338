import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

const LeftPanel = ({ onSignOut }) => {
    return (
        <Menu vertical inverted style={{ height: '100vh', overflowY: 'auto' }}>
            <Menu.Item as={NavLink} to="/" exact>
                <Icon name="list" />
                All Products
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/add-product">
                <Icon name="plus" />
                Add Product
            </Menu.Item>
            <Menu.Item onClick={onSignOut}>
                <Icon name="log out" />
                Logout
            </Menu.Item>
        </Menu>
    );
};

export default LeftPanel;
