import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardImage,
} from 'mdb-react-ui-kit';

const ProductsPage = () => {
    const products = [
        {
            _id: '1',
            name: 'Printers',
            category: 'printers',
            Image: '/images/Photo_copiers.JPG',
            onclick: () => {
                setCurrentLevel(products.find((product) => product._id === '1').SubCategories);
                setCurrentTitle('Printers');
            },
            SubCategories: [
                {
                    _id: '1-1',
                    name: 'Services',
                    category: 'services',
                    Image: 'https://cdn.moglix.com/p/uvwVBrwiX71Hg-xxlarge.jpg',
                    onclick: () => window.location.href = '/products/printers/services',
                },
                {
                    _id: '1-2',
                    name: 'Pre Owned Machines',
                    category: 'printers',
                    Image: 'https://cdn.moglix.com/p/uvwVBrwiX71Hg-xxlarge.jpg',
                    onclick: () => window.location.href = '/products/printers/pre-owned-machines',
                },
                {
                    _id: '1-3',
                    name: 'Accessories and Spares',
                    category: 'accessories',
                    Image: 'https://cdn.moglix.com/p/uvwVBrwiX71Hg-xxlarge.jpg',
                    onclick: () => window.location.href = '/products/printers/accessories-and-spares',
                },
            ],
        },
        {
            _id: '2',
            name: 'CCTV services',
            category: 'cctv',
            Image: '/images/CCTV.JPG',
            onclick: () => window.location.href = '/products/cctv',
        },
        {
            _id: '3',
            name: 'Smart board',
            category: 'smart-board',
            Image: '/images/Interactive_disp.JPG',
            onclick: () => window.location.href = '/products/smart-board',
        },
        {
            _id: '4',
            name: 'Networking',
            category: 'networking',
            Image: '/images/Telecommunication.JPG',
            onclick: () => window.location.href = '/products/networking',
        },
    ];
    const [currentLevel, setCurrentLevel] = useState(products);
    const [currentTitle, setCurrentTitle] = useState('Products');
    
    return (
        <div style={{ padding: '70px' }}>
            <MDBContainer fluid className='my-5'>
                <h2 className='mb-4 text-center' style={{ fontSize: 'bold' }}>
                    {currentTitle}
                </h2>
                <MDBRow>
                    {currentLevel.map((product) => (
                        <MDBCol
                            md='4'
                            lg='3'
                            className='mb-4'
                            key={product._id}
                        >
                            <MDBCard
                                className='product-card'
                                style={{
                                    height: '300px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                onClick={product.onclick}
                            >
                                <div className='d-flex justify-content-between p-3'>
                                    <p className='lead mb-0'>{product.name}</p>
                                </div>
                                <MDBCardImage
                                    src={product.Image}
                                    position='top'
                                    alt={product.name}
                                    style={{
                                        height: '200px',
                                        objectFit: 'contain',
                                        aspectRatio: '1',
                                    }}
                                />
                            </MDBCard>
                        </MDBCol>
                    ))}
                </MDBRow>
            </MDBContainer>
        </div>
    );
}
 
export default ProductsPage;