import React, { useEffect, useState } from 'react';
import { getProducts } from '../../services/Products';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBIcon,
    MDBBtn,
} from 'mdb-react-ui-kit';
import UserLoader from '../Loader/UserLoader';
import './AvailableStyle.css';
import { Message, Dropdown } from 'semantic-ui-react';

const AvailableProducts = (props) => {
    const category = props.category;
    const categories = [
        {
            key: 'cctv',
            text: 'CCTV services',
            value: 'cctv',
        },
        {
            key: 'printers',
            text: 'Printers - Pre owned machines',
            value: 'pre-owned-machines',
        },
        {
            key: 'smart-board',
            text: 'Smart board',
            value: 'smart-board',
        },
        {
            key: 'networking',
            text: 'Networking',
            value: 'networking',
        },
        {
            key: 'services',
            text: 'Services',
            value: 'services',
        },
        {
            key: 'accessories-and-spares',
            text: 'Accessories and Spares',
            value: 'accessories',
        },
    ]
    const [selectedCategory, setSelectedCategory] = useState(category);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 8;
    const [totalRecords, setTotalRecords] = useState(0);
    console.log('props', props.user);

    useEffect(() => {
        fetchAvailableProducts();
    }, [page, selectedCategory]);

    const fetchAvailableProducts = async () => {
        try {
            setLoading(true);
            const response = await getProducts({
                page_no: page,
                page_size: pageSize,
                sort_by: 'name',
                sort_order: 'DESC',
                category: selectedCategory,
                search: '',
            });
            const { data, totRecords } = response.products;
            setAvailableProducts((prevProducts) => {
                const newProducts = data.filter(
                    (newProduct) =>
                        !prevProducts.some(
                            (existingProduct) =>
                                existingProduct._id === newProduct._id
                        )
                );
                return [...prevProducts, ...newProducts];
            });
            setTotalRecords(totRecords);
        } catch (error) {
            console.log('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadMore = async () => {
        setPage(page + 1);
    };

    const handleProductImage = (urls = []) => {
        const defaultImageUrl =
            'https://cdn.moglix.com/p/uvwVBrwiX71Hg-xxlarge.jpg';
        if (urls.length === 0) {
            return defaultImageUrl;
        }
        return urls[0];
    };

    const capitalizeFirstLetter = (string) => {
        if (!string || typeof string !== 'string') {
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div style={{ padding: '70px' }}>
            <MDBContainer fluid className='my-5'>
                <h2 className='mb-4 text-center' style={{ fontSize: 'bold' }}>
                    {capitalizeFirstLetter(category) || 'All Products'}
                </h2>
                {!category && (
                    <Dropdown
                        style = {{
                            marginBottom: '20px',
                            width: '20%'
                        }}
                        placeholder='Select Category'
                        fluid
                        search
                        selection
                        clearable
                        disabled={loading}
                        options={categories}
                        onChange={(e, { value }) => {
                            setAvailableProducts([]);
                            setSelectedCategory(value);
                            setPage(1);
                        }}
                    />
                )}
                <MDBRow>
                    {availableProducts && availableProducts.length
                        ? availableProducts.map((product) => (
                              <MDBCol
                                  md='6'
                                  lg='3'
                                  className='mb-4'
                                  key={product._id}
                              >
                                  <MDBCard
                                      className='product-card'
                                      style={{
                                          height: '300px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                      }}
                                      onClick={
                                          props.manager
                                              ? () =>
                                                    (window.location.href = `/admin/product/${product._id}`)
                                              : () =>
                                                    (window.location.href = `/product/${product._id}`)
                                      }
                                  >
                                      <div className='d-flex justify-content-between p-3'>
                                          <p className='lead mb-0'>
                                              {product.name}
                                          </p>
                                      </div>
                                      <MDBCardImage
                                          src={handleProductImage(
                                              product.imageURLS
                                          )}
                                          position='top'
                                          alt={product.name}
                                          style={{
                                              height: '200px',
                                              objectFit: 'cover',
                                          }}
                                      />
                                      <MDBCardBody
                                          style={{
                                              flex: '1',
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'space-between',
                                          }}
                                      >
                                          <div className='d-flex justify-content-between'>
                                              <p className='small'>
                                                  <a
                                                      href='#!'
                                                      className='text-muted'
                                                  >
                                                      {product.category ||
                                                          'Printer'}
                                                  </a>
                                              </p>
                                              <p className='small text-danger'>
                                              {product.price !== undefined && product.price !== null && product.price !== '' ? '₹' + product.price : 'Price unavailable'}
                                            </p>
                                          </div>
                                      </MDBCardBody>
                                  </MDBCard>
                              </MDBCol>
                          ))
                        : !loading && (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      width: '100%',
                                  }}
                              >
                                  <Message
                                      style={{ width: '70%' }}
                                      header='No items found'
                                      content='No items available under this category.'
                                  />
                              </div>
                          )}
                </MDBRow>
                {loading && <UserLoader />}
                {!loading && availableProducts.length < totalRecords && (
                    <div className='text-center my-4'>
                        <MDBBtn onClick={loadMore} disabled={loadingButton}>
                            {loadingButton ? 'Loading...' : 'Load More'}
                        </MDBBtn>
                    </div>
                )}
            </MDBContainer>
        </div>
    );
};

export default AvailableProducts;
