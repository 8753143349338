import React from 'react';
import { Carousel, Container, Row, Col, Button } from 'react-bootstrap';
import './banner.css';
import { defaultImageUrl } from '../../utils/constants';

function MainBanner() {
  return (
    <div className="main-banner">
      <Carousel>
        <Carousel.Item>
          <Container>
            <Row>
              <Col md={6} className="text-left">
                <div className="banner-content">
                  <h1 className="title">OUR <span className="highlight">LATEST</span> PRODUCT</h1>
                  <p className="description">
                    It is a long established fact that a reader will be distracted by the readable content of a page.
                  </p>
                  <p className="extra-text">
                    Our services include comprehensive sales, expert servicing, reliable rentals, and high-quality spares for all your photocopier (Xerox machine) needs.
                  </p>
                  <ul className="features-list">
                    <li>High-Quality Printing Solutions</li>
                    <li>Affordable Prices and Bulk Discounts</li>
                    <li>Top Brands and Advanced Features</li>
                    <li>Wireless and All-in-One Options</li>
                  </ul>
                  <Button variant="warning" className="see-more-button">See More Products</Button>
                </div>
              </Col>
              <Col md={6} className="text-right">
                <img
                  className="d-block w-100"
                  src={defaultImageUrl}
                  alt="Latest Products"
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item>
          <Container>
            <Row>
              <Col md={6} className="text-left">
                <div className="banner-content">
                  <h1 className="title">OUR <span className="highlight">LATEST</span> PRODUCT</h1>
                  <p className="description">
                    Discover our new range of products designed to meet your needs.
                  </p>
                  <p className="extra-text">
                    We specialize in providing top-notch sales, service, rentals, and spares for photocopiers (Xerox machines), ensuring your business runs smoothly.
                  </p>
                  <ul className="features-list">
                    <li>High-Quality Printing Solutions</li>
                    <li>Affordable Prices and Bulk Discounts</li>
                    <li>Top Brands and Advanced Features</li>
                    <li>Wireless and All-in-One Options</li>
                  </ul>
                  <Button variant="warning" className="see-more-button">See More Products</Button>
                </div>
              </Col>
              <Col md={6} className="text-right">
                <img
                  className="d-block w-100"
                  src={defaultImageUrl}
                  alt="Latest Products"
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item>
          <Container>
            <Row>
              <Col md={6} className="text-left">
                <div className="banner-content">
                  <h1 className="title">OUR <span className="highlight">LATEST</span> PRODUCT</h1>
                  <p className="description">
                    Explore the best products available in the market.
                  </p>
                  <p className="extra-text">
                    From sales and service to rentals and spares, we cover all aspects of photocopier (Xerox machine) needs to keep your operations efficient.
                  </p>
                  <ul className="features-list">
                    <li>High-Quality Printing Solutions</li>
                    <li>Affordable Prices and Bulk Discounts</li>
                    <li>Top Brands and Advanced Features</li>
                    <li>Wireless and All-in-One Options</li>
                  </ul>
                  <Button variant="warning" className="see-more-button">See More Products</Button>
                </div>
              </Col>
              <Col md={6} className="text-right">
                <img
                  className="d-block w-100"
                  src={defaultImageUrl}
                  alt="Latest Products"
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default MainBanner;
